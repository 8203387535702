import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SHubTerms from "./ScheduleHub-Terms";

export default function SHubContent({
  scheduledSlot,
  userTimeslotId,
  handleTimeslotDelete,
  hasPackage,
  hasFetched,
}) {
  const navigate = useNavigate();
  const navigateToPickup = () => {
    navigate("/UserScheduler");
  };

  const [ellipsisDots, setEllipsisDots] = useState(1);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setEllipsisDots((dots) => (dots < 3 ? dots + 1 : 1));
    }, 200);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="SHubContent">
      {!hasFetched ? ( 
        <div className="loading"> {/* pickup data fetching */}
          Loading schedule{".".repeat(ellipsisDots)}
        </div>
      ) : (
        <div className="loaded"> {/* pickup data fetched */}
          {hasPackage && !userTimeslotId ? (
            <div> {/* package available but no scheduled pickup */}
              <button onClick={navigateToPickup}>+ New Pickup</button>
              <div className="no-scheduled-pickup">
                No upcoming pickups scheduled.
              </div>
              <div className="disclaimer"> {/* move to scheduler ? */}
                We kindly request that when scheduling a pickup for your
                packages, you refrain from asking any questions regarding mail
                center operations during your pickup time.
              </div>
            </div>
          ) : userTimeslotId ? (
            <> {/* pickup already scheduled */}
              <div className="scheduled-pickup">
                <p>
                  Pickup Scheduled on <br />
                  <span id="sched-ts">{scheduledSlot}</span>
                </p>
                <div className="sched-ops">
                  <button onClick={navigateToPickup} className="resched-button">
                    Reschedule
                  </button>
                  <button
                    onClick={() => {
                      setIsDeleting(true);
                    }}
                    className="delete-button"
                  >
                    Delete
                  </button>
                </div>
                {isDeleting && (
                  <div className="delete">
                    <p>
                      Are you sure you want to <u>delete</u> your
                      timeslot?
                    </p>
                    <div className="deletion-buttons">
                      <button
                        className="yes-delete"
                        onClick={handleTimeslotDelete}
                      >
                        Yes
                      </button>
                      <button
                        className="no-keep"
                        onClick={() => {
                          setIsDeleting(false);
                        }}
                      >
                        No
                      </button>
                    </div>
                  </div>
                )}
              </div>
              <SHubTerms />
            </>
          ) : (
            <div className="no-packages">
              You currently have no packages to schedule.
            </div>
          )}
        </div>
      )}
    </div>
  );
}
