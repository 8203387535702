import React, { useState, useEffect } from "react";
import DropdownAll from "./DropdownAll";

export default function EditManySelectors({
  selectedStart,
  setSlotStart,
  selectedEnd,
  setSlotEnd,
  countOptions,
  timeslots,
  selectedCountOption,
  setSelectedCountOption,
}) {
  const [filteredEditManyTimeslots, setFilteredEditManyTimeslots] = useState(
    []
  );

  useEffect(() => {
    // Function to update the filtered edit many timeslots
    const updateFilteredEditManyTimeslots = () => {
      const currentTime = new Date();
      const filtered = timeslots.filter(
        (timeslot) =>
          timeslot.slot_start !== null &&
          new Date(timeslot.slot_start) > currentTime
      );
      setFilteredEditManyTimeslots(filtered);
    };

    // call updateFilteredEditManyTimeslots() initially and whenever timeslots change
    updateFilteredEditManyTimeslots();

    // interval to update the filtered edit many timeslots every 1 minutes
    const interval = setInterval(
      updateFilteredEditManyTimeslots,
      1 * 60 * 1000
    );

    return () => clearInterval(interval);
  }, [timeslots]);

  return (
    <div className="AC-slxn-wrapper">
      <div className="AC-timeslot">
        <label>Select starting timeslot:</label>
        <div className="dropdown-container">
          <select value={selectedStart?.value} onChange={setSlotStart}>
            <option value=""> </option>
            {filteredEditManyTimeslots.map((timeslot) => (
              <option
                key={timeslot.id}
                value={timeslot.slot_start.toISOString()}
              >
                {timeslot.slot_start
                  .toLocaleTimeString([], {
                    hour: "numeric",
                    minute: "2-digit",
                  })
                  .slice(0, -3)}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="AC-timeslot">
        <label>Select ending timeslot:</label>
        <div className="dropdown-container">
          <select value={selectedEnd?.value} onChange={setSlotEnd}>
            <option value=""> </option>
            {filteredEditManyTimeslots.map((timeslot) => (
              <option
                key={timeslot.id}
                value={timeslot.slot_start.toISOString()}
              >
                {timeslot.slot_start
                  .toLocaleTimeString([], {
                    hour: "numeric",
                    minute: "2-digit",
                  })
                  .slice(0, -3)}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="AC-count">
        <label>Count:</label>
        <DropdownAll
          options={countOptions}
          value={selectedCountOption}
          onChange={(selectedOption) => setSelectedCountOption(selectedOption)}
        />
      </div>
    </div>
  );
}
