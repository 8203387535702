import React, { useState } from 'react';
import "./Package.css";
import CopyAllIcon from '@mui/icons-material/CopyAll';
import Tooltip from "@mui/material/Tooltip";

const Package = ({ pkgNum = 1, trackingNum = "Error Loading" }) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyClick = () => {
    navigator.clipboard.writeText(trackingNum)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 500);
      })
      .catch((error) => {
        console.error("Error copying text to clipboard:", error);
      });
  };

  return (
    <div className="Package">
      <div className="package-info">
        <p>
          Package {pkgNum}<br aria-hidden="true" />
          Tracking: {trackingNum}
        </p>
      </div>
      <div className="copy-container" role="region" aria-label="Copy Tracking Number">
        <Tooltip title={"Copy tracking number"}><CopyAllIcon className={`copy-icon ${isCopied ? 'copied' : ''}`} fontSize="small" onClick={handleCopyClick} /></Tooltip>
        <div aria-hidden="true" className={`copied-message ${isCopied ? "copy" : ""}`}>Copied!</div>
      </div>
    </div>
  );
}

export default Package;
