import React from "react";
import Scheduler from "../components/Scheduler";
import { useEffect } from "react";
import { useState, useContext } from "react";
import "./UserScheduler.css";
import Footer from "../components/Footer";
import { useMediaQuery } from "@mui/material";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
// import {UserContext} from "../App";
import { UserContext } from "../components/contexts/UserContext";
import { useNavigate } from "react-router-dom";
function UserScheduler() {
  const { userId, uniqueId, boxNumber } = React.useContext(UserContext);

  const isMobile = useMediaQuery("(max-width: 900px)");
  // Arrow back button to go back to the previous page
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  return (
    <>
      <div className={`Scheduler-wrapper ${isMobile ? "mobile" : ""}`}>
        <h1 className="page-title">Schedule Pickup</h1>
        <h2
          className={`page-subtitle-center-alert ${isMobile ? "mobile" : ""}`}
          aria-labelledby="h2"
        >
          Each timeslot runs for 10 minutes. Please select a timeslot and arrive
          at the Mail Center anytime within your scheduled time period.
        </h2>

        <div className="center-container">
          <ArrowCircleLeftIcon
            className="back-button"
            style={{ color: "#012169" }}
            onClick={goBack}
          />
        </div>

        <Scheduler uniqueId={uniqueId} userId={userId} boxNumber={boxNumber} />
      </div>
    </>
  );
}

export default UserScheduler;
