import React, { useState, useEffect } from "react";
import "./FAQ.css";
import { useMediaQuery } from "@mui/material";

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleQuestionClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  const isMobile = useMediaQuery("(max-width: 768px)");

  // Question component method
  const Question = ({ actInd = 0, questionText, answerText }) => {
    const paragraphs = answerText.split("\n");

    return (
      <li
        tabIndex="0"
        className={`question-wrapper ${activeIndex === actInd ? "active" : ""}`}
        onClick={() => handleQuestionClick(actInd)}
      >
        <p className="question">
          <span className={`dropper ${activeIndex === actInd ? "rotate" : ""}`}>
            {activeIndex === actInd ? "⏷" : "⏵"}
          </span>
          {questionText}
          <span className="sr-only">Click to expand</span>
        </p>
        {activeIndex === actInd &&
          paragraphs.map((paragraph, index) => (
            <React.Fragment key={index}>
              <p className="paragraph">{paragraph}</p>
            </React.Fragment>
          ))}
      </li>
    );
  };

  return (
    <div className={`FAQ ${isMobile ? "mobile" : ""}`}>
      <div className="content-wrapper">
        <h1 className="page-title" aria-labelledby="h1">
          FAQ
        </h1>
        <ul tabIndex="0" aria-label="FAQs">
          <h2 className="page-subtitle-center" aria-labelledby="h2">
            PackagePal is a service only for Duke University students. 
          </h2>
          <h2 className="page-subtitle" aria-labelledby="h2">
            FastLane & Scheduling a Pickup
          </h2>
          <Question
            actInd={0}
            questionText={"What is the FastLane?"}
            answerText={
              "The FastLane enables students who have scheduled a pickup to arrive at the mail center at a pre-determined time to pick up all of the contents of their mailbox. Any student can schedule a pickup through their PackagePal, assuming there are packages available for pickup. Please note that by scheduling a package, you agree to the following terms: \n- You will arrive during your scheduled pickup slot \n- You will take all packages available for pickup when you arrive at the Mail Center \n- You will refrain from asking questions about available packages, keys, etc. to allow excellent and efficient customer service. These questions can be answered in the non-scheduled line"
            }
          />
          <Question
            actInd={1}
            questionText="What is the latest time I can schedule a package pickup in a particular timeslot?"
            answerText="For each 10 minute timeslot, you can schedule a package pickup anytime up until that timeslot starts. For example, if you want to schedule a 12:10 P.M. timeslot, you can schedule a pickup for this timeslot up until 12:09 P.M."
          />
          <Question
            actInd={2}
            questionText="What happens if I don't pick up a scheduled pickup?"
            answerText="The Mail Center holds all packages for up to 30 days. After 30 days, the package is returned to its sender. If you miss your scheduled timeslot, you may reschedule your package pickup for another day, as long as it is within the 30 day period."
          />

          <h2 className="page-subtitle">Package Pickup</h2>
          <Question
            actInd={3}
            questionText="How do I pick up a package?"
            answerText="Head to the Student Mail Center in the Bryan Center basement with your box number and number of boxes ready. When you get to the front, tell both of these to a mail center staff member, then scan your ID once you get your box(es). That's it!"
          />
          <Question
            actInd={4}
            questionText="Can someone pick up a package for me?"
            answerText="The short answer is, yes. You won't be able to schedule a package pickup if you're not the one physically getting your box, but someone can pick up a package for you in the regular mail line as long as they have a text from you stating your name, box number, their name, and explicit permission for them to pick up your package."
          />
          <Question
            actInd={5}
            questionText="What happens if I don't pick up a package?"
            answerText="We have not yet received an answer from the Campus Mail Center staff."
          />

          <h2 className="page-subtitle">Campus Mail Policies & Procedures</h2>
          <Question
            actInd={6}
            questionText="When is the mail center open?"
            answerText="The Duke Campus Mail Center is open from 8:30-5:00, Monday-Friday, barring any holidays."
          />

          <Question
            actInd={7}
            questionText="Why is my tracking number different than the one my carrier sent me?"
            answerText="If you order a package through a private carrier, the tracking is marked as delivered once it reaches Duke's central mail processing warehouse. It is then assigned a new tracking number internal to Duke that shows whether it's arrived at the mail center yet or not! If you use a public carrier, the tracking number should be the same either way."
          />
          <Question
            actInd={8}
            questionText="Can I checkout a caddy to help move larger packages?"
            answerText="Yes, free of cost, though the mail center only has limited caddies to loan out. You will have 48 hours to return the caddy undamaged, with a $150.00 charge if the caddy is not returned to the Campus Mail Center staff."
          />
        </ul>
      </div>
    </div>
  );
};

export default FAQ;
