import React, { useEffect, useState } from "react";
import axios from "axios";

export const UserContext = React.createContext();

export const UserProvider = ({ children }) => {
  const [isApiCallComplete, setIsApiCallComplete] = useState(false);
  const [userId, setUserId] = useState("");
  const [uniqueId, setUniqueId] = useState(""); // used
  const [displayName, setDisplayName] = useState(null);
  const [boxNumber, setBoxNumber] = useState(""); // used
  const [affiliation, setAffiliation] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [isStudent, setIsStudent] = useState(true); // isStudent is true if the user has a box number
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // *MEMOIZATION:
  let isCurrentUser = false;
  let lastUser;

  function memoizedFetchCurrentUser() {
    if (isCurrentUser) {
      return lastUser;
    }
    let user = fetchCurrentUser();
    lastUser = user;
    isCurrentUser = true;
    return user;
  }

  const fetchCurrentUser = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_LOCAL_HOST}/api/current_user`
      );

      // console.log("attempted user fetch");
      if (!response.ok) {
        throw new Error("Failed to fetch user data.");
      }

      const data = await response.json();
      // console.log(data);
      if (data === null) {
        // console.log("no data");
        setBoxNumber("nobox");
        setIsStudent(false);
        setDisplayName("Your");
        // console.log("set display name your", displayName);

      }

      if (data && Object.keys(data).length !== 0) {
        setUserId(data.id || "0000");
        let testUniqueId = data.unique_id || "";
        setUniqueId(testUniqueId);
        let testDisplayName = data.display_name || "";
        setDisplayName(testDisplayName);
        let testBoxNumber = data.box_no || "";
        setBoxNumber(testBoxNumber);
        let testAffiliation = data.affiliation || "";
        setAffiliation(testAffiliation);
        let testIsAdmin = data.is_admin || false;
        setIsAdmin(testIsAdmin);

        let testStudent;

        if (data.box_no === "nobox") {
          setIsStudent(false);
        } else {
          setIsStudent(true);
        }
        setIsLoggedIn(true);
      } else {
        throw new Error("Empty user data.");
      }
    } catch (error) {
      // console.error("Error fetching CURR:", error.message);
    } finally {
      setIsApiCallComplete(true);
    }
  };

  useEffect(() => {
    memoizedFetchCurrentUser();
  }, []);

  useEffect(() => {
    if (isApiCallComplete) {
    }
  }, [isApiCallComplete, uniqueId]);

  return (
    <UserContext.Provider
      value={{
        userId,
        setUserId,
        uniqueId,
        setUniqueId,
        displayName,
        setDisplayName,
        boxNumber,
        setBoxNumber,
        affiliation,
        setAffiliation,
        isAdmin,
        setIsAdmin,
        isStudent,
        setIsStudent,
        isLoggedIn,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;