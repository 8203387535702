import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Package from "./Package";
import { UserContext } from "./contexts/UserContext";

export default function PHubContent({ setHasPackages, setHasFetched }) {
  const { uniqueId, boxNumber } = React.useContext(UserContext); // pull vars from user context
  const [packages, setPackages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [ellipsisDots, setEllipsisDots] = useState(1);
  let isComponentMounted = true;

  useEffect(() => {
    const interval = setInterval(() => {
      setEllipsisDots((dots) => (dots < 3 ? dots + 1 : 1));
    }, 200);
    return () => clearInterval(interval);
  }, []);

  // ! MEMOIZATION
  let lastResult, packagesStored = false;
  function memoizedFetchPackages() {
    if (packagesStored) {
      return lastResult;
    }
    let result = fetchPackages();
    lastResult = result;

    packagesStored = true;
    return result;
  }
  const fetchPackages = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_LOCAL_HOST}/api/fetch_asset_ids`,
        {
          unique_id: uniqueId,
          box_number: boxNumber,
        }
      );

      // Update packages state with the received data
      if (isComponentMounted) {
        if (response.data.assetIds) {
          setPackages(response.data.assetIds);
          setHasPackages(true);
        } else {
          setPackages([]);
          setHasPackages(false);
        }

        setIsLoading(false);
      }

      if (response.data.error !== "No asset data found") {
        setPackages(response.data.assetIds);
        setHasFetched(true);
      }
      else if (response.data.error === "No asset data found") {
        setHasFetched(true);
        setIsLoading(false);
      }
      if (response.data.length === 0) {
        setHasFetched(true);
        setIsLoading(false);
      }
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      setHasFetched(true);
    }
  };

  useEffect(() => {
    if (uniqueId && boxNumber) {
      memoizedFetchPackages();
    }
    return () => {
      isComponentMounted = false; // Update flag variable when component unmounts
    };
  }, [uniqueId, boxNumber, setHasPackages]);

  return (
    <div className="PHubContent">
      <div aria-label="Your available packages">
        {isLoading ? (
          <div className="loading">
            Loading packages{".".repeat(ellipsisDots)}
          </div>
        ) : packages.length > 0 ? (
          packages.map((assetId, index) => (
            <div
              aria-label={"Package" + (index + 1)}
              key={"Package" + (index + 1)}
            >
              <Package pkgNum={index + 1} trackingNum={assetId} />
            </div>
          ))
        ) : null}
      </div>
      {!isLoading && packages.length === 0 && (
        <div className="nopkg-msg">
          You currently have no packages to pick up.
        </div>
      )}
    </div>
  );
}
