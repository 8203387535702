import "./PersonTab.css";

const PersonTab = ({
  personName = "FirstName LastName",
  imgSrc = null,
  description = null,
}) => {
  return (
    <div className="staff-member">
      <img
        src={imgSrc}
        aria-hidden="false"
        alt={`Headshot of ${personName}, one of our team leads, wearing a suit and smiling`}
        className={`person ${personName}`}
      ></img>
      <div className="person-info">
        <h3>{personName}</h3>
        <p>{description}</p>
      </div>
    </div>
  );
};

export default PersonTab;
