import React, { useState, useContext, useEffect } from "react";
import "./Address.css";
import { UserContext } from "./contexts/UserContext";
import { useMediaQuery } from "@mui/material";

export default function Address() {
  const [isLoading, setIsLoading] = useState(true);
  const { boxNumber, displayName, uniqueId } = React.useContext(UserContext);
  const [userAddress, setUserAddress] = useState([]);
  const [roomNumber, setRoomNumber] = useState([]);
  const [quad, setQuad] = useState([]);
  const [building, setBuilding] = useState([]);
  const [isFetched, setIsFetched] = useState(false);

  useEffect(() => {
    if (displayName) {
      setIsLoading(false);
      fetchUserAddress();
    }
  }, [displayName]);

  const [ellipsisDots, setEllipsisDots] = useState(1);
  useEffect(() => {
    const interval = setInterval(() => {
      setEllipsisDots((dots) => (dots < 3 ? dots + 1 : 1));
    }, 200);

    return () => clearInterval(interval);
  }, []);


  // timeout for address:
  useEffect(() => {
    const displayMessageTimer = setTimeout(() => {
      if (!isFetched) {
        alert("We're having difficulty loading your information.");
      }
    }, 5000);

    return () => {
      clearTimeout(displayMessageTimer);
    };
  }, [isFetched]);

  const fetchUserAddress = () => {
    // Fetch userTimeslot from the server based on the userId
    fetch(`${process.env.REACT_APP_LOCAL_HOST}/api/fetch_address/${uniqueId}`)
      .then((response) => response.json())
      .then((data) => {
        setUserAddress(data.street_address);
        setRoomNumber(data.rm_number);
        setQuad(data.quad);
        setBuilding(data.building);

        setIsFetched(true);
      })
      .catch((error) => {
        console.error("Error checking user address:", error);
      });
  };

  const isMobile = useMediaQuery("(max-width: 768px)");
  return (
    <div
      role="region"
      aria-label="Address"
      className={`Address ${isMobile ? "mobile" : ""}`}
    >
      {isFetched ? (
        <>
          <h2>Your Address</h2>
          <div className="address-text">
            <p>
              {isLoading ? `Loading${".".repeat(ellipsisDots)}` : displayName}
            </p>
            <p>{userAddress}</p>
            <p>
              Box #{boxNumber}, {quad}, {building} #{roomNumber}
            </p>
            <p>Durham, NC 27708-{boxNumber.slice(-4)}</p>
          </div>
        </>
      ) : (
        <h2>Your Address is {`loading${".".repeat(ellipsisDots)}`}</h2>
      )}
      <a href="https://postoffice.duke.edu/student-mail/" target="_blank">
        <p id="how-to">How to Address Your Mail</p>
      </a>
    </div>
  );
}
