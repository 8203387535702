import Papa from "papaparse";
import { useState } from "react";
import IconButton from "@mui/material/IconButton";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import "./CsvButton.css";

const MessagesCsvButton = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const handleExportClick = () => {
    const formattedStartDate = startDate.toISOString().split("T")[0];
    const formattedEndDate = endDate.toISOString().split("T")[0];

    if (startDate > endDate) {
      alert("Selected start date cannot be later than end date.");
      setStartDate(new Date()); // Clear the start date selection
      setEndDate(new Date());   // Clear the end date selection
      return;
    }
  
    fetch(`${process.env.REACT_APP_LOCAL_HOST}/api/csv/admin_messages?start_date=${formattedStartDate}&end_date=${formattedEndDate}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json(); 
      })
      .then((jsonData) => {
        // Generate CSV with the JSON data
        const csvData = Papa.unparse(jsonData);
        exportData(csvData, getFileName(), "text/csv;charset=utf-8;");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const exportData = (data, fileName, type) => {
    const blob = new Blob([data], { type });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const getFileName = () => {
    const formattedStartDate = startDate.toISOString().slice(0, 10);
    const formattedEndDate = endDate.toISOString().slice(0, 10);
    return `messages_${formattedStartDate}_to_${formattedEndDate}.csv`;
  };

  return (
    <div
      className="msg-csv"
      role="region"
      aria-label="Download Messages for Select Range"
    >
      <div className="date-selec">
        <div className="start-selec">
          <label htmlFor="msg-start-selec">Start:</label>
          <input
            id="msg-start-selec"
            type="date"
            value={startDate ? startDate.toISOString().slice(0, 10) : ""}
            onChange={(e) => {
              const selectedDate = new Date(e.target.value);
              setStartDate(isNaN(selectedDate) ? null : selectedDate);
            }}
          />
        </div>
        <div className="end-selec">
          <label htmlFor="msg-end-selec">End:</label>
          <input
            id="msg-end-selec"
            type="date"
            value={endDate ? endDate.toISOString().slice(0, 10) : ""}
            onChange={(e) => {
              const tempDate = new Date(e.target.value);
              setEndDate(isNaN(tempDate) ? null : tempDate);
            }}
          />
        </div>
      </div>
      <div className="dl-icon">
        <IconButton
          role="button"
          className="dl-icon"
          aria-label="Export to CSV"
          variant="contained"
          color="primary"
          sx={{ height: 60 }}
          onClick={handleExportClick}
        >
          <p aria-hidden="true">Download</p>
          <CloudDownloadIcon aria-hidden="true" />
        </IconButton>
      </div>
    </div>
  );
};

export default MessagesCsvButton;
