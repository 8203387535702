import React, { useState, useEffect, useContext } from "react";
import BoxNum from "../components/BoxNum";
import LineStatus from "../components/LineStatus";
import Address from "../components/Address";
import PHub from "../components/PkgHub-Wrapper";
import "./UserHome.css";
import { useMediaQuery } from "@mui/material";
import Message from "../components/Message";
import { MessageContext } from "../components/contexts/MessageContext";
import axios from "axios";
import { format, parseISO } from "date-fns";
import { UserContext } from "../components/contexts/UserContext";
import { LineStatusContext } from "../components/contexts/LineStatusContext";
import SHub from "../components/ScheduleHub-Wrapper";

function UserHome() {
  const { userId, uniqueId, displayName, boxNumber, isStudent, setIsStudent } =
    React.useContext(UserContext);
  const {
    inputColor,
    setInputColor,
    lastUpdatedColor,
    setLastUpdatedColor,
    statusTime,
    setStatusTime,
  } = useContext(LineStatusContext);
  const {
    inputMessage,
    setInputMessage,
    lastUpdatedMessage,
    setLastUpdatedMessage,
  } = useContext(MessageContext);

  const [displayString, setDisplayString] = useState(displayName);

  // console.log(displayString);

  // loading
  const [wheelVisible, setWheelVisible] = useState(true);
  // loading timeout
  useEffect(() => {
    const displayMessageTimer = setTimeout(() => {
      if (wheelVisible) {
        alert("We're having difficulty loading your information.");
      }
    }, 5000);

    return () => {
      clearTimeout(displayMessageTimer);
    };
  }, [wheelVisible]);

  // name logic
  useEffect(() => {
    // console.log(boxNumber);
    if (displayName) {
      setDisplayString(displayName.split(" ")[0]);
      setWheelVisible(false);
    }
    if (boxNumber === "nobox" || boxNumber === "nopilot") {
      setWheelVisible(false);
      // setDisplayString("Your");
    }
  }, [displayName, boxNumber]);

  const [userTimeslotId, setUserTimeslotId] = useState("");
  const [scheduledSlot, setScheduledSlot] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [confirmedUserTimeslot, setConfirmedUserTimeslot] = useState("");

  const [hasPackages, setHasPackages] = useState(false);
  const [hasFetched, setHasFetched] = useState(false);

  const getPackageStatus = () => {
    return hasPackages;
  };

  const getFetchedStatus = () => {
    return hasFetched;
  };

  const handleTimeslotDelete = () => {
    axios
      .delete(
        `${process.env.REACT_APP_LOCAL_HOST}/api/user_timeslots/${userTimeslotId}`
      )
      .then((response) => {
        // Handle the successful response
        axios
          .get(
            `${process.env.REACT_APP_LOCAL_HOST}/api/cancellation_email/${response.data.timeslot_id}`
          )
          .then(() => {})
          .catch((error) => {
            console.error("Error sending email:", error);
          });

        setUserTimeslotId(null);
        setConfirmedUserTimeslot("");
        // Perform any other necessary actions after deleting the timeslot
      })
      .catch((error) => {
        // Handle the error
        // console.log("Error deleting user timeslot:", error);
      })
      .finally(() => {
        setIsDeleting(false);
      });
  };
  // Use Effects: First, from the Unique ID, grab the box number
  // Then, from the User ID, grab the UserTimeslot ID
  let slotStored = false;
  let lastResult;

  function memoizedFetchUserTimeslotIdFromServer() {
    if (slotStored) {
      return lastResult;
    }
    let result = fetchUserTimeslotIdFromServer();
    lastResult = result;

    slotStored = true;
    return result;
  }
  useEffect(() => {
    if (userId && boxNumber !== "nobox" && boxNumber) {
      memoizedFetchUserTimeslotIdFromServer();
      setIsStudent(true);
    }
  }, [userId, userTimeslotId, scheduledSlot, boxNumber, isStudent]);

  const fetchUserTimeslotIdFromServer = () => {
    fetch(
      `${process.env.REACT_APP_LOCAL_HOST}/api/user_timeslots?user_id=${userId}`
    )
      .then((response) => response.json())
      .then((data) => {
        const userTimeslot = data;

        let scheduledUser;

        if (Array.isArray(userTimeslot)) {
          scheduledUser = userTimeslot.find(
            (userTimeslot) => userTimeslot.status === "scheduled"
          );
        } else {
          scheduledUser = userTimeslot;
        }

        if (scheduledUser && scheduledUser.id) {
          setUserTimeslotId(scheduledUser.id);
        }

        let tempSlot = scheduledUser ? scheduledUser.selected_date_time : null;
        let scheduledTempSlot = "";

        if (tempSlot) {
          try {
            let parsedDate = parseISO(tempSlot);
            if (!isNaN(parsedDate)) {
              scheduledTempSlot = format(
                parsedDate,
                "EEEE, MMMM d 'at' h:mm a"
              );
            } else {
              console.error("Parsed date is invalid:", parsedDate);
            }
          } catch (error) {
            console.error("Error parsing or formatting date:", error);
          }
        }

        setScheduledSlot(scheduledTempSlot);
      })
      .catch((error) => {
        console.error(
          "Error checking user timeslot. It's possible that the user has no scheduled timeslot:",
          error
        );
      });
  };

  function formatDate(dateString) {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleString("en-US", {
      month: "numeric",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    });

    return formattedDate;
  }

  // MOBILE styling
  const isMobile = useMediaQuery("(max-width: 768px)");
  // console.log(displayString);

  return (
    <>
      {!wheelVisible ? (
        <div className={`UserHome ${isMobile ? "mobile" : ""}`}>
          {displayString == null || displayName.toLowerCase() == "your" ? (
            <h1 className="page-title">Your Package Hub</h1>
          ) : displayName && displayString ? (
            <h1 className="page-title">{displayString}'s Package Hub</h1>
          ) : (
            <h1 className="page-title-loading">Loading...</h1>
          )}

          {/* <div className={`UH-flex-wrapper ${isStudent ? "" : "no-stu"}`}>
           */}
          <div className={`UH-flex-wrapper ${isStudent ? "" : "no-stu"}`}>
            <div className={`UH-col1-wrapper ${isMobile ? "mobile" : ""}`}>
              {/* STUDENT */}
              {isStudent ? (
                <>
                  {/* if boxnum !== nopilot */}
                  {boxNumber !== "nopilot" ? (
                    <div className="boxnum">
                      <BoxNum num={boxNumber} />
                    </div>
                  ) : (
                    <></>
                  )}
                  {/* <BoxNum num={boxNumber} />{" "} */}
                  <LineStatus
                    color={inputColor}
                    est={statusTime}
                    update={lastUpdatedColor}
                  />
                  <Message message={inputMessage} time={lastUpdatedMessage} />


                  {/* STUDENT */}
                  {boxNumber !== "nopilot" ? (
                    <Address
                      num={boxNumber}
                      user={uniqueId}
                      displayName={displayName}
                    />
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <>
                  <LineStatus
                    color={inputColor}
                    est={statusTime}
                    update={lastUpdatedColor}
                  />
                  <Message message={inputMessage} time={lastUpdatedMessage} />
                </>
              )}

              {/* PUBLIC */}
            </div>
            {isStudent && boxNumber !== "nopilot" ? (
              <>
                <div aria-label="Package Hub">
                  <PHub
                    setHasPackages={setHasPackages}
                    setHasFetched={setHasFetched}
                  />
                </div>
                {/* STUDENT */}
                <div aria-label="Pickup Scheduler">
                  <SHub
                    scheduledSlot={scheduledSlot}
                    hasPackage={getPackageStatus()}
                    hasFetched={getFetchedStatus()}
                    userId={userId}
                    userTimeslotId={userTimeslotId}
                    uniqueId={uniqueId}
                    handleTimeslotDelete={handleTimeslotDelete}
                    isDeleting={isDeleting}
                  />
                </div>
              </>
            ) : (
              <>
                <div className={`Schedule ${isMobile ? "mobile" : ""}`}>
                  <h2>Scheduler Status</h2>
                  <div className="inner-schedule">
                    <div>
                      {boxNumber === "nopilot" ? (
                        <div className="no-scheduled-pickup">
                          You are not eligible to schedule a pickup or view your
                          packages since you are not part of the pilot program.
                        </div>
                      ) : (
                        <div className="no-scheduled-pickup">
                          You are not eligible to schedule a pickup or view your
                          packages since you do not have a box number at the
                          Bryan Center.
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      ) : (
        <div className={`UserHome ${isMobile ? "mobile" : ""}`}>
          <div className="wheel">
            <div className="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default UserHome;
