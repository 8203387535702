import React, { useState } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import ToolBar from "@material-ui/core/Toolbar";
import Container from "@material-ui/core/Container";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Divider from "@material-ui/core/Divider";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { useMediaQuery } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import UserContext from "./contexts/UserContext";
import { useEffect } from "react";
import Typography from "@mui/material/Typography";
import LogoutIcon from "@mui/icons-material/Logout";

const navigationLinks = [
  // !TODO: Remove before deployment, give admins a separate link.
  { name: "FAQ", to: "/FAQ" },
  { name: "About Us", to: "/AboutUs" },
];

const headerTheme = createTheme({
  breakpoints: {
    values: {
      xs: 200,
      sm: 600,
      md: 950,
    },
  },
});

const useStyles = makeStyles((theme) => ({
  toolBar: {
    flexGrow: "1",
    // height: "100%",
    padding: "0",
    backgroundColor: "#012169",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "0",
    "&.mid": {
      justifyContent: "flex-end",
    },
  },

  link: {
    fontFamily: "Open Sans",
    fontSize: 20,
    textDecoration: "none",
    color: "white",
    fontWeight: 700,
    transition: "background-color 0.3s ease-in-out",
    margin: "0 20px",
    minWidth: "fit-content",
    padding: "5px",
    "&:hover": {
      backgroundColor: "#1d6363",
      borderBottom: "2px solid #fff",
    },
    "&:first-child": {
      marginLeft: 0,
    },
    [theme.breakpoints.down("xs")]: {
      color: "white",
      margin: 0,
      "&:hover": {
        backgroundColor: "#1d6363",
        borderBottom: "2px solid #fff",
      },
    },
  },

  drawer: {
    // backgroundColor: "transparent",
    "&.open": {
      backgroundColor: "#012169",
      color: "white",
    },
  },

  hamburgerMenu: {
    display: "flex",
    alignItems: "center",
  },

  logoutButton: {
    textDecoration: "none",
    fontWeight: 700,
    fontFamily: "Open Sans",
    color: "white",
    fontSize: 20,
    marginRight: 5,
  },
  swipeIcon: {
    color: "white",
  },
  menuIcon: {
    color: "white",
    marginRight: 0,
    paddingRight: 0,
    "&.mid": {
      padding: 20,
      margin: 0,
    },
  },
}));

export default function HamburgerMenu() {
  const { displayName, isLoggedIn } = React.useContext(UserContext);

  const [longDisplay, setLongDisplay] = useState(false);

  const updatedNavigationLinks = isLoggedIn
    ? [
        ...navigationLinks,
        {
          name: "Report a Bug",
          to: "https://duke.qualtrics.com/jfe/form/SV_86t7Zqgt4cv08hE",
          isExternal: true,
        },
      ]
    : navigationLinks;

  const [displayString, setDisplayString] = useState("");

  useEffect(() => {
    if (displayName) {
      setDisplayString(displayName.split(" ")[0]);
      if (displayName.split(" ")[0].length > 20) {
        setLongDisplay(true);
      }
    }
  }, [displayName]);

  const styles = useStyles(headerTheme);
  const [open, setOpen] = useState(false);
  const isMobile = useMediaQuery(headerTheme.breakpoints.down("sm"));
  const isMidsize = useMediaQuery(headerTheme.breakpoints.down("md"));
  const isRegular = useMediaQuery(headerTheme.breakpoints.up("md"));
  const location = useLocation();

  // const pagesWithLogout = ["/UserHome", "/AdminHub"];
  // const showLogoutButton = pagesWithLogout.includes(location.pathname);

  const navigate = useNavigate();
  const handleLogout = () => {
    // *TODO: Add log out link for deployment
    // navigate("https://shib.oit.duke.edu/cgi-bin/logout.pl");
    // component='a' href="https://shib.oit.duke.edu/cgi-bin/logout.pl">
    //               <Typography textAlign="center">Logout</Typography>
  };

  return (
    <ThemeProvider theme={headerTheme}>
      <ToolBar className={`${styles.toolBar} ${isMidsize ? "mid" : ""}`}>
        <div className={styles.hamburgerMenu}>
          {isRegular &&
            updatedNavigationLinks.map((item) =>
              item.isExternal ? ( // if external
                <a
                  href={item.to}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label={item.name}
                  className={`${styles.link}`}
                  color="inherit"
                  variant="button"
                  underline="none"
                  key={item.name}
                  tabIndex="0"
                >
                  {item.name}
                </a>
              ) : (
                // if not external
                <Link
                  role="link"
                  aria-label={item.name}
                  className={`${styles.link}`}
                  color="inherit"
                  variant="button"
                  underline="none"
                  to={item.to}
                  key={item.name}
                  tabIndex="0"
                >
                  {item.name}
                </Link>
              )
            )}

          {isMidsize && (
            <IconButton
              aria-label="Hamburger Menu Button"
              className={`${styles.menuIcon} ${isMidsize ? "mid" : ""}`}
              onClick={() => {
                setOpen(true);
                // aria-expanded="true";
              }}
            >
              <MenuIcon />
            </IconButton>
          )}
        </div>

        {isRegular && isLoggedIn && !longDisplay && (
          <div className={`logout ${open ? "open" : ""}`}>
            <Button
              onClick={handleLogout}
              component="a"
              href="https://shib.oit.duke.edu/cgi-bin/logout.pl"
            >
              Logout {displayString}
            </Button>
            <LogoutIcon className={`${styles.logIcon} `} />
          </div>
        )}
        {isRegular && isLoggedIn && longDisplay && (
          <div className={`logout ${open ? "open" : ""}`}>
            <Button
              onClick={handleLogout}
              component="a"
              href="https://shib.oit.duke.edu/cgi-bin/logout.pl"
            >
              Logout
            </Button>
            <LogoutIcon className={`${styles.logIcon} `} />
          </div>
        )}
      </ToolBar>
      <SwipeableDrawer
        anchor="right"
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        classes={{ paper: `${styles.drawer} ${open ? "open" : ""}` }}
      >
        <div className={styles.drawerHeader} onClick={() => setOpen(false)}>
          <IconButton>
            <ChevronRightIcon className={`${styles.swipeIcon}`} />
          </IconButton>
        </div>
        <Divider />
        <List>
          {updatedNavigationLinks.map((item) =>
            item.isExternal ? ( // if external
              <a
                href={item.to}
                target="_blank"
                rel="noopener noreferrer"
                aria-label={item.name}
                className={`${styles.link}`}
                color="inherit"
                variant="button"
                underline="none"
                key={item.name}
                tabIndex="0"
              >
                {item.name}
              </a>
            ) : (
              // if not external
              <Link
                role="link"
                aria-label={item.name}
                className={`${styles.link}`}
                color="inherit"
                variant="button"
                underline="none"
                to={item.to}
                key={item.name}
                tabIndex="0"
              >
                {item.name}
              </Link>
            )
          )}

          {isLoggedIn && !longDisplay && (
            <div className={`logout ${open ? "open" : ""}`}>
              <Button onClick={handleLogout} component="a" href="/logout">
                Logout {displayString}
              </Button>
              <LogoutIcon className={`${styles.logIcon}`} />
            </div>
          )}
          {isLoggedIn && longDisplay && (
            <div className={`logout ${open ? "open" : ""}`}>
              <Button onClick={handleLogout} component="a" href="/logout">
                Logout
              </Button>
              <LogoutIcon className={`${styles.logIcon}`} />
            </div>
          )}
        </List>
      </SwipeableDrawer>
    </ThemeProvider>
  );
}
