import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AppBar, Toolbar, Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { useMediaQuery } from "@mui/material";
import HamburgerMenu from "./HamburgerMenu";
import { UserContext } from "./contexts/UserContext";
import "./Header.css";
import logoImage from "../assets/crux.svg";

const headerTheme = createTheme({
  breakpoints: {
    values: {
      xs: 200,
      sm: 600,
      md: 950,
    },
  },
});

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: "#012169",
    width: "auto",
    boxShadow: "none",
    justifyContent: "center",
    display: "flex",
    flexDirection: "row !important",
    zIndex: 1100,
  },
  // Add any other styles you want to define here
  logoImage: {
    maxWidth: "85%", // Adjust the width as needed
    height: "100%",
    marginRight: theme.spacing(1), // Add margin if needed
  },
}));

const Header = React.forwardRef((props, ref) => {
  const navigate = useNavigate();
  const { isAdmin } = useContext(UserContext);

  const navigateToHome = () => {
    const homeRoute = isAdmin ? "/AdminHub" : "/";
    navigate(homeRoute);
  };

  const isMobile = useMediaQuery(headerTheme.breakpoints.down("sm"));
  const isMidsize = useMediaQuery(headerTheme.breakpoints.down("md"));

  const classes = useStyles(headerTheme); // Use the useStyles hook here

  return (
    <ThemeProvider theme={headerTheme}>
      <AppBar position="sticky" className={`Header ${classes.appBar} ${isMobile ? "mobile" : ""} ${isMidsize ? "mid" : ""}`} ref={ref}>
          <div
            aria-label="Duke PackagePal"
            role="link" 
            className={`duke-wrapper ${isMobile ? "mobile" : ""} ${
              isMidsize ? "mid" : ""
            }`}
            onClick={navigateToHome}
          >
            {/* {!isMobile &&(<div className="header-duke" onClick={navigateToHome}>Duke</div>)} */}
            {!isMobile && <img src={logoImage} alt="Logo" className={classes.logoImage} />}
            {isMobile && 
                <div className="header-sub mobile">Duke PackagePal</div>
            }
          </div>
          <HamburgerMenu role="region" tabIndex="-1" />
        </AppBar>
    </ThemeProvider>
  );
});

export default Header;
