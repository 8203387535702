import React from "react";

export default function ActualTable({ filteredTimeslots }) {
  return (
    <table id="actual-table">
      <thead>
        <tr>
          <th>Start Time</th>
          <th>Remaining Slots</th>
        </tr>
      </thead>
      <tbody>
        {filteredTimeslots
          .sort((a, b) => a.slot_start.getTime() - b.slot_start.getTime())
          .map((timeslot) => (
            <tr key={timeslot.id}>
              <td>
                {timeslot.slot_start
                  ? timeslot.slot_start
                      .toLocaleTimeString([], {
                        hour: "numeric",
                        minute: "2-digit",
                      })
                      .slice(0, -3)
                  : null}
              </td>
              <td>{timeslot.count}</td>
            </tr>
          ))}
      </tbody>
    </table>
  );
}
