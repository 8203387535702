import React from "react";
// MUI icons
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

export default function PreviewTable({
  viewOption,
  slotDropDownOption,
  hourDropDownOption,
  preview,
  handleCellClick,
}) {
  return (
    <table id="preview-table">
      <thead>
        <tr>
          <th id="tc-timeslot">Slot</th>
          <th id="tc-student">Student</th>
          <th id="tc-boxnum">Box #</th>
          <th id="tc-numpkg">Pkgs</th>
          <th id="tc-status">Status</th>
          <th className="sp-stat-col">Arr.</th>
          <th className="sp-stat-col">Miss.</th>
        </tr>
      </thead>
      <tbody>
        {preview
          .filter((user) => {
            if (viewOption === "Day") {
              return true;
            }
            // slot
            if (slotDropDownOption) {
              const selectedTime = slotDropDownOption;

              const userTime = new Date(user.slot)
                .toLocaleTimeString([], {
                  hour: "numeric",
                  minute: "2-digit",
                })
                .slice(0, -3);

              return selectedTime === userTime;
            }
            // hour
            if (hourDropDownOption) {
              const isPM = hourDropDownOption.includes("PM");
              let selectedHour = hourDropDownOption.split(":")[0];

              if (isPM) {
                selectedHour = (parseInt(selectedHour, 10) + 12).toString();
              }

              const userHour = new Date(user.slot)
                .getHours()
                .toString()
                .padStart(2, "0")
                .trim(); // Trim any leading/trailing whitespaces

              return selectedHour === userHour;
            }

            return true;
          })
          .sort((a, b) => {
            const timeA = new Date(a.slot).getTime();
            const timeB = new Date(b.slot).getTime();
            return timeA - timeB;
          })
          .map((user, index) => {
            return (
              <tr key={index}>
                <td>
                  {new Date(user.slot)
                    .toLocaleTimeString([], {
                      hour: "numeric",
                      minute: "2-digit",
                    })
                    .slice(0, -3)}
                </td>
                <td>{user.name}</td>
                <td>{user.boxNumber}</td>
                <td>{user.num_packages}</td>
                <td>
                  {user.status
                    ? user.status.charAt(0).toUpperCase() + user.status.slice(1)
                    : ""}
                </td>
                <td className="status-cell">
                  <CheckCircleIcon
                    role="button"
                    className={`arr-${user.status}`}
                    onClick={() => handleCellClick(user, "arrived")}
                  />
                </td>
                <td className="status-cell">
                  <CancelIcon
                    role="button"
                    className={`mis-${user.status}`}
                    onClick={() => handleCellClick(user, "missed")}
                  />
                </td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
}
