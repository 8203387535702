import { useMediaQuery } from "@mui/material";
import PHubContent from "./PkgHub-Content";
import "./PkgHub.css";

export default function PHub({ setHasPackages, setHasFetched }) {
  const isMobile = useMediaQuery("(max-width: 768px)");

  return (
    <div
      aria-label="Package Hub"
      className={`PHub ${isMobile ? "mobile" : ""}`}
    >
      <h2>Packages to Pick Up</h2>
      <PHubContent
        setHasPackages={setHasPackages}
        setHasFetched={setHasFetched}
      />
    </div>
  );
}
