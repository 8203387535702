import React, { useContext } from "react";
import TimeslotsContext from "./contexts/TimeslotsContext";

export default function ButtonConfirmation({ selectedDate, setSelectedDate }) {
  const {
    timeslotModifyOption,
    setTimeslotModifyOption,
    setCounts,
    setSetCounts,
    resetCounts,
    setResetCounts,
    isButtonConfirmed,
    handleSubmit,
    setIsDataConfirmed,
  } = useContext(TimeslotsContext);

  const handleFormSubmit = () => {
    const tempSelectedDate = selectedDate;
    handleSubmit();
    setSelectedDate(tempSelectedDate);
  };
  return (
    <div className="button-group">
      <button
        className="submit-button"
        onClick={handleFormSubmit}
        disabled={
          !timeslotModifyOption || setCounts || resetCounts || isButtonConfirmed
        }
      >
        Confirm
      </button>
      <button
        className="cancel-button"
        onClick={() => {
          setSetCounts(false);
          setResetCounts(false);
          setTimeslotModifyOption("");
          setIsDataConfirmed(false);
        }}
        disabled={!timeslotModifyOption}
      >
        Cancel
      </button>
    </div>
  );
}
