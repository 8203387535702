import React from "react";
// MUI icons, components
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Tooltip from "@mui/material/Tooltip";

export default function VisIcon({handleCollapse, tooltipMessage, isTabCollapsed}) {
  return (
    <button className="vis-icon" onClick={handleCollapse}>
      <Tooltip title={tooltipMessage}>
        {isTabCollapsed ? (
          <>
            <VisibilityIcon />
            <p className="sr-only">Display Timeslot Table</p>
          </>
        ) : (
          <>
            <VisibilityOffIcon />
            <p className="sr-only">Hide Timeslot Table</p>
          </>
        )}
      </Tooltip>
    </button>
  );
}
