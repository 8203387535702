import React, { useContext } from "react";
import TimeslotsContext from "./contexts/TimeslotsContext";
import DropdownAll from "./DropdownAll";

export default function TTSelectors() {
  const {
    selectedTimeslotId,
    selectedCountOption,
    setSelectedCountOption,
    timeslotModifyOption,
    setTimeslotModifyOption,
    setCounts,
    resetCounts,
    isButtonConfirmed,
    handleTimeslotSelect,
    timeslotOptions,
    countOptions,
  } = useContext(TimeslotsContext);

  return (
    <div className="AC-selectors">
      <div className="AC-slot">
        <button
          className={`ac-button timeslot-option-button ${
            timeslotModifyOption === "oneSlot" ? "selected" : ""
          }`}
          onClick={() => setTimeslotModifyOption("oneSlot")}
          disabled={
            timeslotModifyOption === "allSlots" ||
            timeslotModifyOption === "editManySlots" ||
            timeslotModifyOption === "cancelAll" ||
            setCounts ||
            resetCounts ||
            isButtonConfirmed
          }
        >
          EDIT ONE SLOT
        </button>

        <button
          className={`ac-button timeslot-option-button ${
            timeslotModifyOption === "allSlots" ? "selected" : ""
          }`}
          onClick={() => setTimeslotModifyOption("allSlots")}
          disabled={
            timeslotModifyOption === "oneSlot" ||
            timeslotModifyOption === "editManySlots" ||
            timeslotModifyOption === "cancelAll" ||
            setCounts ||
            resetCounts ||
            isButtonConfirmed
          }
        >
          EDIT ALL TIMES
        </button>

        <button
          className={`ac-button timeslot-option-button ${
            timeslotModifyOption === "editManySlots" ? "selected" : ""
          }`}
          onClick={() => setTimeslotModifyOption("editManySlots")}
          disabled={
            timeslotModifyOption === "oneSlot" ||
            timeslotModifyOption === "allSlots" ||
            timeslotModifyOption === "cancelAll" ||
            setCounts ||
            resetCounts ||
            isButtonConfirmed
          }
        >
          EDIT A TIME RANGE
        </button>
        <button
          className={`ac-button timeslot-option-button ${
            timeslotModifyOption === "cancelAll" ? "selected" : ""
          } cancel-all`}
          onClick={() => setTimeslotModifyOption("cancelAll")}
          disabled={
            timeslotModifyOption === "oneSlot" ||
            timeslotModifyOption === "allSlots" ||
            timeslotModifyOption === "editManySlots" ||
            setCounts ||
            resetCounts ||
            isButtonConfirmed
          }
        >
          CANCEL DAY'S REMAINING SLOTS
        </button>
      </div>

      {timeslotModifyOption === "oneSlot" && (
        <div className="AC-slxn-wrapper">
          <div className="AC-timeslot">
            <label>Select a timeslot:</label>
            <select
              value={selectedTimeslotId?.value}
              onChange={handleTimeslotSelect}
            >
              <option value=""> </option>

              {timeslotOptions.map((option) => (
                <option key={option.id} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>

            <div className="AC-count">
              <label>Set Remaining Students:</label>
              <DropdownAll
                options={countOptions}
                value={selectedCountOption}
                onChange={(selectedOption) =>
                  setSelectedCountOption(selectedOption)
                }
              />
            </div>
          </div>
        </div>
      )}
      {timeslotModifyOption === "allSlots" && (
        <div className="AC-count">
          <label>Set Remaining Students:</label>
          <DropdownAll
            options={countOptions}
            value={selectedCountOption}
            onChange={(selectedOption) =>
              setSelectedCountOption(selectedOption)
            }
          />
        </div>
      )}
    </div>
  );
}
