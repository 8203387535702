import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import { format, parseISO } from "date-fns";
import axios from "axios";
import Package from "./Package";
import { UserContext } from "./contexts/UserContext";

// MUI components
import { DayCalendarSkeleton, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import { createTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

import "./Scheduler.css";

function ServerDay(props) {
  const {
    day,
    outsideCurrentMonth,
    timeslots,
    isWeekend,
    datesWithTimeslots,
    ...other
  } = props;

  const [hasSpace, setHasSpace] = useState(false);

  useEffect(() => {
    const formattedDate = format(day, "yyyy-MM-dd");

    // Fetch user timeslots for the specific date when the component mounts
    fetch(`${process.env.REACT_APP_LOCAL_HOST}/api/timeslots`)
      .then((response) => response.json())
      .then((data) => {
        const timeslotsArray = Array.isArray(data) ? data : Object.values(data);
        const hasSpace = timeslotsArray.some(
          (item) =>
            item.selected_date_time &&
            item.selected_date_time.startsWith(formattedDate)
        );
        setHasSpace(hasSpace);
      })
      .catch((error) => {
        console.error("Error fetching user timeslots:", error);
      });

    // Check for `hasSpace` based on the current `timeslots`
    const hasSpace = timeslots.some(
      (item) =>
        item.selected_date_time &&
        item.selected_date_time.startsWith(formattedDate)
    );
    setHasSpace(hasSpace);
  }, [day, timeslots]);

  const formattedDate = useMemo(() => format(day, "yyyy-MM-dd"), [day]);

  const dateColor = useMemo(() => {
    const currentDate = new Date(); // Get the current date
    currentDate.setHours(0, 0, 0, 0); // Set the time to midnight (00:00:00)

    const hasPassed = day < currentDate;

    if (outsideCurrentMonth) {
      // not accessible contrast but should be near invisible for intended functionality
      return "#A9A9A9";
    } else if (hasPassed) {
      return "#707070";
    }
    return;
  }, [day, outsideCurrentMonth]);

  const dateBGColor = useMemo(() => {
    const hasPassed = day < new Date();
    if (
      datesWithTimeslots && // Add a check for datesWithTimeslots here
      datesWithTimeslots.includes(formattedDate)
    ) {
      return "rgba(161 183 13 /.25)";
    }
    return null;
  }, [datesWithTimeslots, hasSpace, day]);

  return (
    <PickersDay
      {...other}
      outsideCurrentMonth={outsideCurrentMonth}
      day={day}
      style={{
        color: dateColor,
        backgroundColor: dateBGColor,
      }}
      role="button"
    />
  );
}

export default function Scheduler() {
  const { uniqueId, userId, boxNumber, affiliation } = useContext(UserContext);
  // alert(boxNumber);
  const currentDate = new Date();

  const [selectedDate, setSelectedDate] = useState(currentDate);
  const [selectedTimeslot, setSelectedTimeslot] = useState(null);
  const [scheduledSlot, setScheduledSlot] = useState(null);
  const [selectedTimeslotId, setSelectedTimeslotId] = useState(null);
  const [userTimeslotId, setUserTimeslotId] = useState(null);
  // const [userId, setUserId] = useState(null);
  const [jsonData, setJsonData] = useState([]);
  const [timeslots, setTimeslots] = useState([]);
  const [timeslotsVisible, setTimeslotsVisible] = useState(false); // State variable for timeslot visibility
  const [isConfirming, setIsConfirming] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [checkUserConfirmed, setCheckUserConfirmed] = useState(false);
  const [confirmedUserTimeslot, setConfirmedUserTimeslot] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const [activeButton, setActiveButton] = useState(null);
  const [isAllSlotsUnavailable, setIsAllSlotsUnavailable] = useState(false);
  const [isSameDay, setIsSameDay] = useState("");
  const [isBefore, setIsBefore] = useState("");

  const [hasFetched, setHasFetched] = useState(false);
  const [ellipsisDots, setEllipsisDots] = useState(1);
  useEffect(() => {
    const interval = setInterval(() => {
      setEllipsisDots((dots) => (dots < 3 ? dots + 1 : 1));
    }, 200);

    return () => clearInterval(interval);
  }, []);
  // storing number of packages and user id
  // const [usersToAdmin, setUsersToAdmin] = useState([]);

  const handleDateSelect = (date) => {
    if (selectedDate && selectedDate.getTime() === date.getTime()) {
      setSelectedDate(null);
      setTimeslotsVisible(false);
    } else {
      setSelectedDate(date);
      setSelectedTimeslot(null);
      setIsConfirmed(false);
      setIsDeleting(false);
      setTimeslotsVisible(true);
    }
  };

  // Create a ref for the element you want to scroll to
  const textRef = useRef();

  // Define the function to handle scrolling to the element
  const handleScrollToText = () => {
    textRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const fetchUserTimeslotIdFromServer = () => {
    fetch(`${process.env.REACT_APP_LOCAL_HOST}/api/user_timeslots?user_id=${userId}`)
        .then((response) => response.json())
        .then((data) => {
            const userTimeslot = data;

            let scheduledUser;

            if (Array.isArray(userTimeslot)) {
                scheduledUser = userTimeslot.find((userTimeslot) => userTimeslot.status === "scheduled");
            } else {
                scheduledUser = userTimeslot;
            }

            if (scheduledUser && scheduledUser.id) {
                setUserTimeslotId(scheduledUser.id);
            }

            let tempSlot = scheduledUser ? scheduledUser.selected_date_time : null;
            let scheduledTempSlot = "";

            if (tempSlot) {
                try {
                    let parsedDate = parseISO(tempSlot);
                    if (!isNaN(parsedDate)) {
                        scheduledTempSlot = format(parsedDate, "EEEE, MMMM d 'at' h:mm a");
                    } else {
                        console.error("Parsed date is invalid:", parsedDate);
                    }
                } catch (error) {
                    console.error("Error parsing or formatting date:", error);
                }
            }

            setScheduledSlot(scheduledTempSlot);
        })
        .catch((error) => {
            console.error("Error checking user timeslot. It's possible that the user has no scheduled timeslot:", error);
        });
};




  useEffect(() => {
    //alert("fetchUserTimeslotIdFromServer");
    if (userId) {
      fetchUserTimeslotIdFromServer();
    }
  }, [userId]);

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_LOCAL_HOST}/api/timeslots/condensed_timeslots`
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // Make sure data is an array
        if (Array.isArray(data)) {
          setJsonData(data);
        } else {
          // Handle the scenario where data is not an array (e.g., when server response is not in the expected format)
          console.error("Received data is not an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error loading JSON data:", error);
        // Handle the error gracefully, e.g., display a message to the user or retry the fetch.
      });
  }, []);

  useEffect(() => {
    //alert("setIsAllSlotsUnavailable");
    const allUnavailable = timeslots.every((slot) => !slot.isAvailable);
    setIsAllSlotsUnavailable(allUnavailable);
  }, [timeslots]);

  useEffect(() => {
    if (Array.isArray(jsonData)) {
      // alert("checks if selected date is available in the db");
      // checks if selected date is available in the db
      if (selectedDate) {
        const selectedDateFormatted = format(selectedDate, "yyyy-MM-dd");
        const filteredData = jsonData.filter((item) => {
          if (!item.has_passed) {
            const itemDate = format(parseISO(item.date), "yyyy-MM-dd");
            return itemDate === selectedDateFormatted;
          }
          return false; // Exclude items with null date
        });
        if (filteredData.length > 0) {
          const timeslots = filteredData.map((item) => ({
            id: item.id,
            slot_start: new Date(item.slot_start),
            slot_end: new Date(item.slot_end),
            count: item.count,
            isAvailable: item.count > 0,
          }));
          setTimeslots(timeslots);
          setTimeslotsVisible(true);
        } else {
          setTimeslots([]);
          setTimeslotsVisible(false);
        }
      }
    } else {
      console.error("jsonData is not an array:", jsonData);
    }
  }, [selectedDate, jsonData]);

  const [datesWithTimeslots, setDatesWithTimeslots] = useState([]);

  // Function to extract unique dates with timeslots from jsonData
  const extractDatesWithTimeslots = (data) => {
    const uniqueDates = new Set(); // Use a Set to store unique dates
    data.forEach((item) => {
      const date = new Date(item.slot_start).toISOString().substr(0, 10);
      uniqueDates.add(date);
    });
    return Array.from(uniqueDates);
  };

  useEffect(() => {
    // After jsonData is fetched and available, extract unique dates with timeslots
    if (Array.isArray(jsonData)) {
      const datesWithSlots = extractDatesWithTimeslots(jsonData);
      setDatesWithTimeslots(datesWithSlots);
    }
  }, [jsonData]);

  const handleConfirmationCancel = () => {
    setActiveButton(null);
    setIsConfirming(false);
    setIsConfirmed(false);
    setIsDeleting(false);
    setSelectedTimeslot(null);
    setSelectedTimeslotId(null);
  };

  const handleConfirmationConfirm = () => {
    // setIsConfirming(false);
    // setTimeslotsVisible(false);
    const user_id = userId;
    const timeslot_id = selectedTimeslotId;
    const status = "scheduled";
    const selected_date_time = selectedTimeslot.toISOString();
    const num_packages = packages?.length;
    // Save the selected date and time to local storage
    //Current user data to send
    const requestData = {
      user_timeslot: {
        user_id,
        timeslot_id,
        status,
        selected_date_time,
        num_packages,
      },
    };
    //Post request, send scheduled slot to database

    axios
      .post(
        `${process.env.REACT_APP_LOCAL_HOST}/api/user_timeslots`,
        requestData
      )
      .then((response) => {
        const { id, timeslot_id } = response.data;
        setUserTimeslotId(id);
        setSelectedTimeslot(selected_date_time);
        setSelectedTimeslotId(timeslot_id);

        setIsConfirming(false);
        setIsConfirmed(true);
        hasUserScheduled();
      });

    axios
      .get(`${process.env.REACT_APP_LOCAL_HOST}/api/users`)
      .then((response) => {
        const users = response.data;
        const user = users.find((user) => user.id === userId);
        const user_email = user.email;
      })

      .catch((error) => {
        // Handle the error
        console.error("Error saving user timeslot:", error);
      });

    axios
      .get(
        `${process.env.REACT_APP_LOCAL_HOST}/api/confirmation_email/${timeslot_id}`
      )
      .then(() => {})
      .catch((error) => {
        console.error(
          "Error sending email, be sure users table is populated with email:",
          error
        );
      });
  };

  const handleTimeslotCancel = () => {
    setSelectedTimeslot(null);
    setIsConfirming(false);
    setIsConfirmed(false);
    setActiveButton(null);
    setIsDeleting(true);
    setTimeslotsVisible(true);
    axios
      .delete(
        `${process.env.REACT_APP_LOCAL_HOST}/api/user_timeslots/${userTimeslotId}`
      )
      .then((response) => {
        // Handle the successful response
        axios
          .get(
            `${process.env.REACT_APP_LOCAL_HOST}/api/cancellation_email/${response.data.timeslot_id}`
          )
          .then(() => {})
          .catch((error) => {
            console.error("Error sending email:", error);
          });
        setSelectedDate(null);
        //setUserTimeslotId(null);
        setSelectedTimeslot(null);
        setSelectedTimeslotId(null);
        setIsConfirmed(false); // Disable buttons
        setConfirmedUserTimeslot("");
        setCheckUserConfirmed(false);
      })
      .catch((error) => {
        // Handle the error
        console.error("Error canceling user timeslot:", error);
      })
      .finally(() => {
        setIsDeleting(false);
      });
  };

  const hasUserScheduled = () => {
    // When a person reloads a page, checks if they have already scheduled
    fetch(
      `${process.env.REACT_APP_LOCAL_HOST}/api/user_timeslots?user_id=${userId}`
    )
      .then((response) => {
        if (response.ok) {
          setCheckUserConfirmed(true);
          return response.json();
        } else {
          setCheckUserConfirmed(false);
          throw new Error("User timeslot not found");
        }
      })
      .then((data) => {
        // Ensure data is an array before using find
        if (Array.isArray(data)) {
          const myTimeslot = data.find(
            (entry) => entry.status === "scheduled"
          ).selected_date_time;
          setConfirmedUserTimeslot(
            format(parseISO(myTimeslot), "EEEE, MMMM d 'at' h:mm a")
          );
          // Handle the scenario where data is not an array
        } else if (data && data.status === "scheduled") {
          // If data is an object with a status of "scheduled"
          let myTimeslot = data.selected_date_time;
          setConfirmedUserTimeslot(
            format(parseISO(myTimeslot), "EEEE, MMMM d 'at' h:mm a")
          );
        }
        // setCheckUserConfirmed(false);
        // console.error("Data is not an array:", data);
        // }
      })
      .catch((error) => {
        setCheckUserConfirmed(false);
        console.error("Error checking user timeslot. It's possible that the user has no scheduled timeslot:", error);
      });
  };

  useEffect(() => {
    //alert("Check that user has scheduled throughout their session");

    // Check that user has scheduled throughout their session

    hasUserScheduled();
  }, [userId]);

  const acTheme = createTheme({
    breakpoints: {
      values: {
        sm: 375,
        md: 915,
      },
    },
  });

  const isMobile = useMediaQuery(acTheme.breakpoints.down("sm"));
  const isMidsize = useMediaQuery(acTheme.breakpoints.down("md"));

  const sortingOptions = [
    { value: "all", label: "All Timeslots" },

    { value: "hour", label: "Sort by Hour" },
  ];

  const [selectedSortingOption, setSelectedSortingOption] = useState("all");

  const [selectedHour, setSelectedHour] = useState(null);

  const confirmRef = useRef(null);

  useEffect(() => {
    //alert("isConfirming");

    if (isConfirming && confirmRef.current) {
      confirmRef.current.scrollIntoView({
        behavior: "smooth",

        block: "start",
      });
    }
  }, [isConfirming]);
  const [packages, setPackages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // !MEMOIZATION
  let packagesStored = false;
  let lastResult;

  function memoizedFetchPackages() {
    if (packagesStored) {
      return lastResult;
    }
    let result = fetchPackages();
    lastResult = result;

    packagesStored = true;
    return result;
  }

  const fetchPackages = async () => {
    // alert("Unique ID: ", uniqueId);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_LOCAL_HOST}/api/fetch_asset_ids`,
        {
          unique_id: uniqueId,
          box_number: boxNumber,
          // unique_id: 1236441,
          // box_number: 99490,
        }
      );
      if (response.data.error !== "No asset data found") {
        setPackages(response.data.assetIds);
        setHasFetched(true);
      }
      if (response.data.error === "No asset data found") {
        // setPackages([]);
        setHasFetched(true);
        setIsLoading(false);
      }
      if (response.data.length === 0) {
        setHasFetched(true);
        setIsLoading(false);
      }

      // alert("SCLogic Data: ", response.data.assetIds);

      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setHasFetched(true);
    }
  };

  useEffect(() => {
    // alert("fetchPackage");
    if (uniqueId && boxNumber) {
      memoizedFetchPackages();
    }
  }, [uniqueId, boxNumber]);

  const isWeekend = (date) => {
    const day = date.getDay();

    return day === 0 || day === 6;
  };

  // ARIA Functions

  function ariaTimeslotSelected() {
    const selectedMessage = document.getElementById("selectedMessage");
    selectedMessage.textContent = "Timeslot selected! Confirm below.";
  }

  return (
    <div className={`Scheduler ${isMidsize ? "mid" : ""}`}>
      {/* <h1>HERE IS SCHEDULER</h1> */}

      <div role="region" aria-label="Packages" className="packages">
        <h2>Packages to Pick Up</h2>

        <div className="inner-pickup">
          {isLoading ? (
            <div className="loading">Loading {".".repeat(ellipsisDots)}</div>
          ) : packages?.length > 0 ? (
            packages.map((assetId, index) => (
              <Package key={index} pkgNum={index + 1} trackingNum={assetId} />
            ))
          ) : hasFetched && packages.length === 0 ? (
            <p>You currently have no packages.</p>
          ) : (
            <div className="loading">`Loading${".".repeat(ellipsisDots)}`</div>
          )}
        </div>
      </div>

      {!isLoading && packages?.length > 0 && (
        <div
          className={`picker ${isMidsize ? "mid" : ""} ${
            isMobile ? "mobile" : ""
          }`}
          role="region"
          aria-label="Pickup Scheduler"
        >
          <h2>Select a Date and Timeslot</h2>

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateCalendar
              // defaultValue={currentDate}
              views={["month", "day"]}
              showDaysOutsideCurrentMonth
              fixedWeekNumber={6}
              value={selectedDate}
              date={selectedDate}
              onChange={handleDateSelect}
              minDate={new Date()}
              shouldDisableDate={(date, position) => {
                if (position === "end") {
                  return false;
                }
                return isWeekend(date);
              }}
              renderLoading={() => <DayCalendarSkeleton />}
              slots={{ day: ServerDay }}
              slotProps={{
                day: {
                  timeslots,
                  isWeekend,
                  datesWithTimeslots,
                },
              }}
            />
          </LocalizationProvider>

          {/* Timeslots */}

          {!checkUserConfirmed && selectedDate && (
            <>
              <h2 id="timeslot-h2">
                Timeslots for {format(selectedDate, "MMMM dd, yyyy")}:
              </h2>

              {timeslots.length === 0 ? (
                <p>
                  No timeslots available for{" "}
                  {format(selectedDate, "MMMM dd, yyyy")}.
                </p>
              ) : (
                <>
                  <div className="dropdowns">
                    {/* Select dropdown for sorting */}

                    <div className="dd-sort">
                      <label htmlFor="sortingOption">Sort by</label>

                      <select
                        id="sortingOption"
                        value={selectedSortingOption}
                        onChange={(e) => {
                          setSelectedSortingOption(e.target.value);
                          setSelectedHour(null); // Reset selected hour when changing sorting option
                        }}
                      >
                        {sortingOptions.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    </div>

                    {/* Select dropdown for hour */}

                    <div className="dd-hour-wrapper">
                      {selectedSortingOption === "hour" ? (
                        <div className="dd-hour">
                          <label htmlFor="hour">Select Hour</label>

                          <select
                            id="hour"
                            value={selectedHour}
                            onChange={(e) => setSelectedHour(e.target.value)}
                          >
                            <option value="">Select an hour</option>

                            {Array.from(
                              new Set(
                                timeslots

                                  .filter((timeslot) => timeslot.isAvailable)

                                  .sort(
                                    (a, b) =>
                                      a.slot_start.getTime() -
                                      b.slot_start.getTime()
                                  )

                                  .map((timeslot) => {
                                    const startHour =
                                      timeslot.slot_start.getHours();

                                    return startHour;
                                  })
                              )
                            ).map((hour) => (
                              <option key={hour} value={hour}>
                                {hour === 0
                                  ? "12 AM"
                                  : hour === 12
                                  ? "12 PM"
                                  : hour < 12
                                  ? `${hour} AM`
                                  : `${hour - 12} PM`}
                              </option>
                            ))}
                          </select>
                        </div>
                      ) : (
                        <div className="dd-hour-placeholder"></div>
                      )}
                    </div>

                    {selectedSortingOption === "all" && (
                      <ul>
                        {timeslots

                          .filter((timeslot) => timeslot.isAvailable)

                          .sort(
                            (a, b) =>
                              a.slot_start.getTime() - b.slot_start.getTime()
                          )

                          .map((timeslot) => (
                            <button
                              ref={textRef}
                              key={timeslot.id}
                              onClick={() => {
                                setSelectedTimeslot(timeslot.slot_start);
                                handleScrollToText();
                                setSelectedTimeslotId(timeslot.id);

                                setIsConfirming(true);
                                ariaTimeslotSelected();

                                if (activeButton === timeslot.id) {
                                  setActiveButton(null); // Remove active styling if the same button is clicked again
                                } else {
                                  setActiveButton(timeslot.id); // Set the active button
                                }
                              }}
                              className={
                                activeButton === timeslot.id ? "active" : ""
                              }
                              disabled={isConfirmed}
                            >
                              {timeslot.slot_start.toLocaleTimeString([], {
                                hour: "numeric",

                                minute: "2-digit",
                              })}
                            </button>
                          ))}
                      </ul>
                    )}
                  </div>

                  <div
                    id="selectedMessage"
                    className="sr-only"
                    aria-live="polite"
                    aria-atomic="true"
                  ></div>

                  {selectedHour && timeslotsVisible && (
                    <ul>
                      {timeslots

                        .filter((timeslot) => {
                          if (
                            selectedSortingOption === "hour" &&
                            selectedHour !== ""
                          ) {
                            const startHour = timeslot.slot_start.getHours();

                            return (
                              timeslot.isAvailable &&
                              startHour === Number(selectedHour)
                            );
                          }

                          return timeslot.isAvailable;
                        })

                        .sort(
                          (a, b) =>
                            a.slot_start.getTime() - b.slot_start.getTime()
                        )

                        .map((timeslot) => (
                          <button
                            key={timeslot.id}
                            onClick={() => {
                              setSelectedTimeslot(timeslot.slot_start);

                              setSelectedTimeslotId(timeslot.id);

                              setIsConfirming(true);

                              if (activeButton === timeslot.id) {
                                setActiveButton(null); // Remove active styling if the same button is clicked again
                              } else {
                                setActiveButton(timeslot.id); // Set the active button
                              }
                            }}
                            className={
                              activeButton === timeslot.id ? "active" : ""
                            }
                            disabled={isConfirmed}
                          >
                            {timeslot.slot_start.toLocaleTimeString([], {
                              hour: "numeric",

                              minute: "2-digit",
                            })}
                          </button>
                        ))}
                    </ul>
                  )}
                </>
              )}
            </>
          )}

          {/* Confirmation */}

          {isConfirming && timeslots.length > 0 && (
            <div className="confirm">
              <p>
                Are you sure you want to save the selected date and time? You'll
                receive a confirmation email shortly.
              </p>
              <div className="confirmation-buttons">
                <button
                  aria-label="Cancel this selection"
                  className="cancel-button"
                  onClick={handleConfirmationCancel}
                >
                  Cancel
                </button>

                <button
                  aria-label="Confirm this selection"
                  className="confirm-button"
                  onClick={handleConfirmationConfirm}
                >
                  Confirm
                </button>
              </div>
            </div>
          )}

          {checkUserConfirmed && (
            // If the user has already confirmed, display cancel button

            <div className="sched-msg">
              <p>
                Scheduled package pickup date and time: <br />{" "}
                <span id="sched-ts">{confirmedUserTimeslot}</span>
              </p>
              <button
                className={`button cancel-button`}
                onClick={() => {
                  setIsDeleting(true);
                }}
              >
                Cancel This Timeslot
              </button>

              {isDeleting && (
                <div className="delete">
                  <div aria-label="Confirm message">
                    <p>
                      Are you sure you want to <u>delete</u> your timeslot?
                    </p>
                  </div>

                  <div className="deletion-buttons">
                    <button
                      role="button"
                      className="yes-delete"
                      onClick={handleTimeslotCancel}
                    >
                      <span aria-hidden="false">Yes</span>

                      <span className="sr-only">Delete this timeslot</span>
                    </button>

                    <button
                      role="button"
                      className="no-keep"
                      onClick={() => {
                        setIsDeleting(false);
                      }}
                    >
                      <span aria-hidden="false">No</span>

                      <span className="sr-only">Keep this timeslot</span>
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
