import React, { useState, useEffect, useContext } from "react";
import "./ScheduleHub.css";
import SHubContent from "./ScheduleHub-Content";
import { useMediaQuery } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { UserContext } from "./contexts/UserContext";

export default function SHub({
  scheduledSlot,
  userTimeslotId,
  handleTimeslotDelete,
  hasPackage,
  hasFetched,
}) {
  const { userId, uniqueId, displayName, boxNumber, isStudent, setIsStudent } =
    React.useContext(UserContext);
  // Table data
  const rows = [
    { id: 1, date: "2023-05-01", time: "12:00", status: "Completed" },
    { id: 2, date: "2023-05-02", time: "14:00", status: "Pending" },
    { id: 3, date: "2023-05-03", time: "16:00", status: "Cancelled" },
  ];

  // Dialog state
  const [open, setOpen] = useState(false);
  const [timeslots, setTimeslots] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const fetchByUserID = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_LOCAL_HOST}/api/user_timeslots/by_user/${userId}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  useEffect(() => {
    const fetchTimeslots = async () => {
      setLoading(true);
      try {
        const data = await fetchByUserID();
        setTimeslots(data);
        setError(null);
      } catch (err) {
        setError("Failed to load data");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchTimeslots();
  }, []);

  const isMobile = useMediaQuery("(max-width: 768px)");
  return (
    <div className={`SHub ${isMobile ? "mobile" : ""}`}>
      <h2>
        Pickup Scheduler{" "}
        <span style={{ position: "relative", top: "5px" }}>
          <InfoIcon onClick={handleClickOpen} />
        </span>
      </h2>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle className="dialogTitle">Prior Pickups</DialogTitle>
        <DialogContent>
          <DialogContentText className="dialogContent">
            {loading ? (
              <p>Loading...</p>
            ) : error ? (
              <p>{error}</p>
            ) : timeslots ? (
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Time</TableCell>
                      <TableCell>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {timeslots.map((timeslot) => (
                      <TableRow key={timeslot.id}>
                        <TableCell>{timeslot.timeslot.date}</TableCell>
                        <TableCell>
                          {new Date(
                            timeslot.selected_date_time
                          ).toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: true,
                          })}
                        </TableCell>
                        <TableCell>{timeslot.status}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <p>Your prior pickups will be shown here.</p>
            )}
          </DialogContentText>
        </DialogContent>
      </Dialog>

      <SHubContent
        scheduledSlot={scheduledSlot}
        hasPackage={hasPackage}
        hasFetched={hasFetched}
        userTimeslotId={userTimeslotId}
        handleTimeslotDelete={handleTimeslotDelete}
      />
    </div>
  );
}
