import React, { useState } from "react";
import "./ContactUs.css";
import { UserContext } from "../components/contexts/UserContext";
import { useEffect } from "react";
export default function ContactUs() {
  // useEffect(() => {
  //   window.location.href =
  //     "https://duke.qualtrics.com/jfe/form/SV_86t7Zqgt4cv08hE";
  //   console.log("Redirecting to Qualtrics...");
  // }, []);

  return (
    <>
      <div className="ContactUs">
        <h1 className="page-title">Redirecting...</h1>
      </div>
    </>
  );
}

// !Old deprecated contact form:
// const [message, setMessage] = useState("Type your message here...");
// const [submittedMessage, setSubmittedMessage] = useState("");
// const [savedMessage, setSavedMessage] = useState(""); // New state variable to save the message
// const { uniqueId } = React.useContext(UserContext);
// const [isSubmitted, setIsSubmitted] = useState(false);

// const handleMessageChange = (event) => {
//   setMessage(event.target.value);
// };

// const handleMessageFocus = () => {
//   if (message === "Type your message here...") {
//     setMessage("");
//   }
// };

// const handleMessageBlur = () => {
//   if (message === "") {
//     setMessage("Type your message here...");
//   }
// };

// const getMessageClass = () => {
//   if (message === "Type your message here...") {
//     return "default-text";
//   } else {
//     return "user-text";
//   }
// };

// const handleSaveMessage = () => {
//   setSavedMessage(message); // Save the current message to the savedMessage state
// };

// const handleSubmit = async (event) => {
//   event.preventDefault();
//   setSubmittedMessage(savedMessage); // Use the savedMessage instead of the message

//   if (savedMessage === "Type your message here...") {
//     // Show an error message to the user or handle the empty message case as desired
//     alert("Please include something in the message.");
//     return; // Stop the form submission
//   }

//   setMessage("Type your message here..."); // Reset the message field after submit

//   // Perform form submission or any other necessary actions
//   const data = {
//     message: savedMessage,
//   };

//   try {
//     // Send the user input to the backend (Rails API) using a POST request
//     const response = await fetch(
//       `${process.env.REACT_APP_LOCAL_HOST}/api/contact_us_email`,
//       {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(data),
//       }
//     );
//     if (response.ok) {
//       // Successfully sent the data
//       setIsSubmitted(true);
//     } else {
//       // Failed to send the data
//       setIsSubmitted(false);
//       // You can handle the error or display an error message to the user
//     }
//   } catch (error) {
//     console.error("Error sending data:", error);
//   }
// };

// return (
//   <>
//     <div className="ContactUs">
//       {!isSubmitted ? (
//         <div className="presubmit">
//           <h1 className="page-title">Have a bug to report?</h1>
//           <h2 id="contact-label">This message will be sent to the Student Developer team.</h2>
//           <form
//             aria-label="type your message here"
//             id="contactForm"
//             onSubmit={handleSubmit}
//           >
//             <label id="message-label" htmlFor="contact-message">
//               Message
//             </label>
//             <textarea
//               id="contact-message"
//               aria-label="Enter your message"
//               value={message}
//               onChange={handleMessageChange}
//               onFocus={handleMessageFocus}
//               onBlur={handleMessageBlur}
//               required
//               className={getMessageClass()}
//             ></textarea>

//             <button
//               type="submit"
//               id="sub"
//               className="contact-button"
//               onClick={handleSaveMessage}
//             >
//               Submit
//             </button>
//           </form>
//         </div>
//       ) : (
//         <div className="postsubmit">
//           <h1 className="page-title">Thank you for your feedback!</h1>
//           <h2 id="ask">Is there anything else you would like to share?</h2>
//           <button
//             type="submit"
//             id="resub"
//             className="contact-button"
//             onClick={() => {
//               setIsSubmitted(false);
//             }}
//           >
//             Submit Another Ticket
//           </button>
//         </div>
//       )}
//     </div>
//   </>
// );
// }
// export default ContactUs;
