import React, { useState, useEffect } from "react";
import axios from "axios";
import parse from "date-fns/parse";
import PreviewTable from "./StudentPreview-Table";
import StudentPreviewContext from "./contexts/StudentPreviewContext";
// MUI icons, methods
import PersonOffIcon from "@mui/icons-material/PersonOff";
import { useMediaQuery } from "@mui/material";
import "./StudentPreview.css";

export default function StudentPreview() {
  const { preview, selectedDate, setPreview } =
    React.useContext(StudentPreviewContext);
  const [selectedCells, setSelectedCells] = useState([]);

  const tableScroll = useMediaQuery("(max-width: 1146px)");

  useEffect(() => {
    setSelectedCells([]);
  }, [preview]);

  const handleCellClick = async (user, type) => {
    const oldStatus = user.status;
    const newStatus = oldStatus === type ? "scheduled" : type;

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_LOCAL_HOST}/api/user_timeslots/${user.userid}/update_status_by_timeslot/${user.timeslotid}`,
        { status: newStatus }
      );
      if (response.status === 200) {
      } else {
        console.error("Failed to update the status in the database");
      }
    } catch (error) {
      console.error("Error updating the status in the database:", error);
    }

    const updatedPreview = preview.map((previewUser) => {
      if (previewUser.userid === user.userid && previewUser.timeslotid === user.timeslotid) {
        return { ...previewUser, status: newStatus };
      }
      return previewUser;
    });

    setPreview(updatedPreview);
  };

  // TIMESLOTS, view option: slot, hour, day (default)
  const [viewOption, setViewOption] = useState("Day");
  
  useEffect(() => {
    setViewOption("Day"); // reset view option to Day when selected date changes
  }, [selectedDate]);

  // array of previewable (scheduled) timeslots
  const timeSlotsArray = preview.map((user) => {
    return new Date(user.slot)
      .toLocaleTimeString([], {
        hour: "numeric",
        minute: "2-digit",
      })
      .slice(0, -3);
  });
  // converted to set to remove duplicates
  const uniqueTimeSlots = [...new Set(timeSlotsArray)];

  // initialize dropdown selections
  const [hourDropDownOption, setHourDropDownOption] = useState("");
  const [slotDropDownOption, setSlotDropDownOption] = useState("");

  // hours array
  const timeHoursArray = preview.map((user) => {
    const date = new Date(user.slot);
    const hour = date.getHours();
    const meridiem = hour < 12 ? "AM" : "PM";
    const formattedHour = (hour % 12 || 12).toString();
    const timeWithMinutes =
      (formattedHour < 10 ? "0" + formattedHour : formattedHour) +
      ":00 " +
      meridiem;
    return timeWithMinutes;
  });

  const uniqueTimeHours = [...new Set(timeHoursArray)];

  // methods to handle views
  const handleSlotView = () => {
    setViewOption("Slot");
  };
  const handleHourView = () => {
    setViewOption("Hour");
  };
  const handleDayView = () => {
    setViewOption("Day");
  };

  return (
    <div aria-label="Preview Students for Selected Date" class="student-preview">
      {selectedDate === null ? (
        <div className="no-date-selec">
          <h3>Preview for: </h3>
          <p>No date is selected in calendar</p>
        </div>
      ) : (
        <>
          <h3>{viewOption} View</h3>
          {preview.length > 0 && (
            <div className="AC-selectors">
              <div className="AC-slot" id="SP-slot">
                {/* SLOT BUTTON & VIEW */}
                {preview.length > 0 && (
                  <button onClick={handleSlotView}>ONE SLOT</button>
                )}

                {/* HOUR BUTTON & VIEW */}
                {preview.length > 0 && (
                  <button onClick={handleHourView}>BY HOUR</button>
                )}

                {/* DAY BUTTON & VIEW */}
                {preview.length > 0 && (
                  <button onClick={handleDayView}>BY DAY</button>
                )}
              </div>
              {viewOption === "Hour" && (
                <div className="hour-select">
                  <label>Select an hour:</label>
                  <select
                    value={hourDropDownOption}
                    onChange={(e) => setHourDropDownOption(e.target.value)}
                  >
                    <option value="">Select an hour</option>
                    {uniqueTimeHours
                      .sort((a, b) => {
                        const timeA = parse(a, "h:mm a", new Date());
                        const timeB = parse(b, "h:mm a", new Date());
                        return timeA - timeB;
                      })
                      .map((slot, index) => (
                        <option key={index} value={slot}>
                          {slot}
                        </option>
                      ))}
                  </select>
                </div>
              )}

              {viewOption === "Slot" && (
                <div className="timeslot-select">
                  <label>Select a timeslot:</label>
                  <select
                    value={slotDropDownOption}
                    onChange={(e) => setSlotDropDownOption(e.target.value)}
                  >
                    <option value="">Select a time slot</option>
                    {uniqueTimeSlots
                      .sort((a, b) => {
                        const timeA = parse(a, "h:mm a", new Date());
                        const timeB = parse(b, "h:mm a", new Date());
                        return timeA - timeB;
                      })
                      .map((slot, index) => (
                        <option key={index} value={slot}>
                          {slot}
                        </option>
                      ))}
                  </select>
                </div>
              )}
              {viewOption === "Day" && <div></div>}

              {tableScroll && <p id="table-scroll">Scroll to view</p>}
            </div>
          )}

          {preview.length === 0 ? (
            <div className="nobody-scheduled">
              <PersonOffIcon id="nobody-icon" />
              <p>No students scheduled.</p>
            </div>
          ) : (
            <PreviewTable
              viewOption={viewOption} /* selected view */
              slotDropDownOption={slotDropDownOption} /* selected slot */
              hourDropDownOption={hourDropDownOption} /* selected hour */
              preview={preview} /* array of scheduled students + data */
              handleCellClick={handleCellClick} /* onclick function */
            />
          )}
        </>
      )}
    </div>
  );
}
