import React, { useState, useEffect } from "react";
import PersonTab from "../components/PersonTab";
import "./AboutUs.css";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import Team from "../assets/new-team-photo.jpeg";
import Microsoft from "../assets/microsoft-sponsor.jpeg";
import Mail from "../assets/campus-mail-logo-square.png";
import CodePlus from "../assets/plus-logo.png";
import Harry from "../assets/harry.png";
import Maulik from "../assets/maulik.jpeg";

const AboutUs = () => {
  const [showStaffMembers, setShowStaffMembers] = useState(false);
  const toggleStaffMembers = () => {
    setShowStaffMembers(!showStaffMembers);
  };

  const [showTeam, setShowTeam] = useState(false);
  const toggleTeam = () => {
    setShowTeam(!showTeam);
  };

  const [showSponsors, setShowSponsors] = useState(false);
  const toggleSponsors = () => {
    setShowSponsors(!showSponsors);
  };

  const auTheme = createTheme({
    breakpoints: {
      values: {
        xs: 200,
        sm: 600,
        md: 950,
      },
    },
  });

  const isMobile = useMediaQuery(auTheme.breakpoints.down("sm"));
  const isMidsize = useMediaQuery(auTheme.breakpoints.down("md"));

  const [isDescriptionVisible, setIsDescriptionVisible] = useState(false);
  const toggleShowDescription = () => {
    setIsDescriptionVisible(!isDescriptionVisible);
  };

  return (
    <ThemeProvider theme={auTheme}>
      <div
        className={`AboutUs ${isMobile ? "mobile" : ""}${
          isMidsize ? "mid" : ""
        }`}
      >
        <h1 className="page-title">About Us</h1>
        <div
          tabIndex="0"
          role="region"
          aria-label="Mail Staff"
          className={`mail-staff ${isMobile ? "toggleable" : ""} ${
            showStaffMembers ? "active" : ""
          }`}
          onClick={toggleStaffMembers}
          aria-expanded={showStaffMembers}
        >
          <h2>Mail Center</h2>
          {(!isMobile || showStaffMembers) && (
            <div className="members">
              <div className="mission-statement">
                <p>
                  The Campus Mail Services (CMS) mission is to provide
                  exceptional mail services to all Duke University and Health
                  System patrons. With over 379 years of combined mail
                  experience and years of service at Duke, Campus Mail Services
                  staff prides itself on providing expedient delivery and
                  courteous mail services to students, faculty and staff within
                  the Duke community.
                </p>
                <p>
                  The CMS department acts as the liaison between many internal
                  departments and organizations within the Duke network. We
                  strive to build good working relationships and incorporate
                  best mailing practices to ensure efficient and accurate
                  handling of U.S. Postal Service mail, packages, and Duke
                  inter-campus mail.
                </p>
              </div>
            </div>
          )}
        </div>
        <div
          tabIndex="0"
          role="region"
          aria-label="Developer Team"
          className={`dev-team ${isMobile ? "toggleable" : ""} ${
            showTeam ? "active" : ""
          }`}
          onClick={toggleTeam}
        >
          {isMobile && <p className="sr-only">Click to Expand</p>}
          <h2>Development Team</h2>
          {(!isMobile || showTeam || isDescriptionVisible) && (
            <>
              <div id="student-team">
                <img
                  src={Team}
                  alt="Duke students on this Code+ team standing in the Allen Board Room"
                  id="team-photo"
                />
                <p id="team-desc">
                  The 2023 Code+ PackagePal Team (from left to
                  right): Bea Radtke, Eileen Cai, Biruk Amene, Aaron Diefes,
                  Alex Pieroni, Ria Kapoor.
                </p>
              </div>
              <h2>Team Leads</h2>
              <div id="team-leads">
                <PersonTab
                  personName="Harry Thakkar"
                  imgSrc={Harry}
                  description="I am a Sr. IT Analyst in Duke OIT working in Enterprise Systems and Support - Web Application (ESS-web) team as a DevOps lead. I have been with Duke OIT for last 16 years. My hobbies include traveling, visiting new cafes, spending time with family and eating out."
                />
                <PersonTab
                  personName="Maulik Parikh"
                  imgSrc={Maulik}
                  description="I am a Sr. IT Analyst at Duke OIT working in Enterprise Systems and Support. I have been in the IT industry for 25+ years and have been with Duke OIT for the last four years. My hobbies include technology, traveling, food & making new friends."
                />
              </div>

              <button id="project-button" onClick={toggleShowDescription}>
                <span
                  className={`dropper ${isDescriptionVisible ? "rotate" : ""}`}
                >
                  {isDescriptionVisible ? "⏷" : "⏵"}
                  {isDescriptionVisible
                    ? "  Hide Description"
                    : "  Read About the Project"}
                </span>
              </button>
              {isDescriptionVisible && (
                <p id="toggled">
                  <a
                    href="https://codeplus.duke.edu/projects/campus-mail-package-pickup"
                    target="_blank"
                  >
                    A team of students
                  </a>{" "}
                  collaborated with the Office of Information Technology and
                  Campus Mail Services to develop a web app called the PackagePal.
                  The app aims to solve the problem of long lines at
                  the Student Mailbox Center by providing an efficient solution
                  for students to collect their packages. The PackagePal
                  offers students access to their mail information, including
                  their box number and a list of currently available packages
                  for pickup. Additionally, it enables students to select a
                  preferred time for package pickup, streamlining the process
                  and reducing waiting times. Duke Campus Mailbox Services
                  sought an IT solution to address the increased stress on staff
                  and capacity following the COVID-19 pandemic, which led to a
                  significant rise in the handling of parcels for students. The
                  team's solution involves creating a student portal that
                  seamlessly integrates with the Package Training system from
                  DPO (presumably a package delivery organization). Through this
                  portal, students can easily schedule their pickup time from a
                  list of available slots, further optimizing the package
                  collection process.
                </p>
              )}
            </>
          )}
        </div>
        <div
          tabIndex="0"
          aria-label="Our Sponsors"
          role="region"
          className={`sponsors ${isMobile ? "toggleable" : ""} ${
            showSponsors ? "active" : ""
          }`}
          onClick={toggleSponsors}
        >
          <h2>Our Sponsors and Stakeholders</h2>
          {(!isMobile || showSponsors) && (
            <div id="logos">
              <img src={Microsoft} alt="Microsoft Logo" />
              <img src={Mail} alt="Campus Mail Services Logo" />
              <img src={CodePlus} alt="Code Plus Logo" />
            </div>
          )}
        </div>
      </div>
    </ThemeProvider>
  );
};

export default AboutUs;
