import React from "react";

export default function SHubTerms() {
  return (
    <div className="terms" aria-label="Pickup Terms">
      By scheduling a package, you agree to the following terms: <br />
      - You will arrive during your scheduled pickup slot <br />
      - You will take all packages available for pickup when you arrive at the
      Mail Center <br />
      - You will refrain from asking questions about available packages, keys,
      etc. This can be done in the non-scheduled line <br />
    </div>
  );
}
