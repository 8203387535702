import React from "react";
import "./Footer.css";
import PlaceIcon from "@mui/icons-material/Place";
import EmailIcon from "@mui/icons-material/Email";
import CallIcon from "@mui/icons-material/Call";
import HomeIcon from '@mui/icons-material/Home';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

export default function Footer() {
  
  const footerTheme = createTheme({
    breakpoints: {
      values: {
        md: 950,
      },
    },
  });

  const isMidsize = useMediaQuery(footerTheme.breakpoints.down("md"));

  return (
    <ThemeProvider theme={footerTheme}>
      <div
        role="region"
        aria-label="Footer"
        className={`Footer ${
          isMidsize ? "mid" : ""
        }`}
      >
        <div className="services" aria-description="Campus Mail Services Website Link">
          <HomeIcon className = "contact-icon" />
          <a href="https://postoffice.duke.edu" target="_blank">Duke Campus Mail Services</a>
        </div>
        <div className="address" aria-description="Campus Mail Mailing Address">
          <PlaceIcon className="contact-icon" />
          3523 Hillsborough Rd Durham, NC 27705
        </div>
        <div className="phone" aria-description="Campus Mail Main Phone">
          <CallIcon className="contact-icon" />
          919-382-4500
        </div>
        <div
          className="email"
          aria-description="Campus Mail Email Address"
          onClick={() =>
            (window.location.href = "mailto:dukepostoffice@duke.edu")
          }
          style={{ cursor: "pointer", textDecoration: "underline" }}
        >
          <EmailIcon className="contact-icon" />
          <a>dukepostoffice@duke.edu</a>
        </div>
      </div>
    </ThemeProvider>
  );
}
