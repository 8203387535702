import React, { useContext, useState, useEffect } from "react";
import format from "date-fns/format";
// CONTEXTS
import StudentPreviewContext from "./contexts/StudentPreviewContext";
import TimeslotsContext from "./contexts/TimeslotsContext";

// CUSTOM components
import TTSelectors from "./TimeslotsTable-Selectors";
import EditManySelectors from "./TimeslotsTable-EditMany";
// CSS
import "./TimeslotsTable.css";
import VisIcon from "./TimeslotsTable-VisIcon";
import ActualTable from "./TimeslotsTable-Table";
import ButtonConfirmation from "./TimeslotsTable-Confirmation";

export default function TimeslotsTable() {
  const {
    timeslots,
    selectedCountOption,
    setSelectedCountOption,
    timeslotModifyOption,
    countOptions,
    selectedStart,
    selectedEnd,
    setSlotStart,
    setSlotEnd,
  } = useContext(TimeslotsContext);

  const { selectedDate, setSelectedDate } = React.useContext(
    StudentPreviewContext
  );

  // get and format selected date
  useEffect(() => {
    const storedSelectedDate = localStorage.getItem("selectedDate");
    if (storedSelectedDate) {
      setSelectedDate(new Date(storedSelectedDate));
    }
  }, []);
  useEffect(() => {
    if (selectedDate) {
      localStorage.setItem("selectedDate", selectedDate.toISOString());
    }
  }, [selectedDate]);

  // * TOGGLE Timeslot editor view
  const [isTabCollapsed, setIsTabCollapsed] = useState(false);
  const tooltipMessage = isTabCollapsed ? "Display Table" : "Hide Table";

  const handleCollapse = () => {
    if (timeslots.length !== 0) {
      setIsTabCollapsed(!isTabCollapsed);
    }
  };

  // *Setting view of if the timeslot has passed or not, disabling the Timeslot Editor
  const [timeslotView, setTimeslotView] = useState("curr");
  const [lastSlot, setLastSlot] = useState([]);
  useEffect(() => {
    if (selectedDate) {
      let timeslotsSort = timeslots.sort(
        (a, b) => a.slot_start.getTime() - b.slot_start.getTime()
      );

      // check if array is empty before grabbing last one
      if (timeslotsSort.length !== 0) {
        let timeslotLast = timeslotsSort[timeslots.length - 1];
        setLastSlot(timeslotLast);
      }

      if (lastSlot.has_passed) {
        setTimeslotView("passed");
      } else {
        setTimeslotView("curr");
      }
    }
  }, [selectedDate, timeslots, lastSlot]);

  // *TIMESLOT POPULATION

  // State to store the filtered timeslots
  const [filteredTimeslots, setFilteredTimeslots] = useState([]);

  useEffect(() => {
    // update the filtered timeslots
    const updateFilteredTimeslots = () => {
      const currentTime = new Date();
      const filtered = timeslots.filter(
        (timeslot) => new Date(timeslot.slot_start) > currentTime
      );
      setFilteredTimeslots(filtered);
    };

    // call updateFilteredTimeslots() initially and whenever timeslots change
    updateFilteredTimeslots();

    if (filteredTimeslots.length === 0) {
      setIsTabCollapsed(true);
    }

    // interval to update the filtered timeslots every 1 minutes
    const interval = setInterval(updateFilteredTimeslots, 1 * 60 * 1000);

    // clean up the interval on component unmount
    return () => clearInterval(interval);
  }, [timeslots]);

  return (
    <div className="timeslot-table">
      <h2 className="admin-title">Timeslot Editor</h2>
      <div className="TimeslotsTable">
        {selectedDate === null ? (
          <div className="no-date-selec">
            <h3 className="tt-title">Edit slots on: </h3>
            <p>No date is selected in calendar</p>
          </div>
        ) : timeslotView === "curr" ? (
          <>
            <VisIcon
              handleCollapse={handleCollapse}
              tooltipMessage={tooltipMessage}
              isTabCollapsed={isTabCollapsed}
            />{" "}
            {/* separate component here */}
            {!isTabCollapsed && <TTSelectors />} {/* separate component here */}
            {timeslotModifyOption == "cancelAll" && (
              <p>
                Are you sure you want to cancel <br /> all slots for the
                selected date?
              </p>
            )}
            {timeslotModifyOption === "editManySlots" && (
              <EditManySelectors
                selectedStart={selectedStart}
                setSlotStart={setSlotStart}
                selectedEnd={selectedEnd}
                setSlotEnd={setSlotEnd}
                countOptions={countOptions}
                timeslots={timeslots}
                selectedCountOption={selectedCountOption}
                setSelectedCountOption={setSelectedCountOption}
              />
            )}
            {/* separate component here */}
            {!isTabCollapsed && timeslotModifyOption != "" && (
              <ButtonConfirmation
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
              />
            )}
            {!isTabCollapsed && timeslots.length > 0 ? (
              <ActualTable filteredTimeslots={filteredTimeslots} />
            ) : (
              <div className="noslot">
                {timeslots.length === 0 ? (
                  <span>
                    No timeslots available for{" "}
                    {format(selectedDate, "MMMM dd, yyyy")}.
                  </span>
                ) : (
                  <div>Timeslot Editor collapsed.</div>
                )}
              </div>
            )}
          </>
        ) : (
          <p>Slots for past days cannot be edited.</p>
        )}
      </div>
    </div>
  );
}
