import Papa from "papaparse";
import { useState } from "react";
// MUI icons
import IconButton from "@mui/material/IconButton";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import "./CsvButton.css";

const FastLaneCsvButton = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const handleExportClick = () => {
    const formattedStartDate = startDate.toISOString().split("T")[0];
    const formattedEndDate = endDate.toISOString().split("T")[0];

    // Check if the selected start date is earlier than the end date
    if (startDate > endDate) {
      alert("Selected start date cannot be later than end date.");
      setStartDate(new Date()); // Clear the start date selection
      setEndDate(new Date()); // Clear the end date selection
      return;
    }

    fetch(
      `${process.env.REACT_APP_LOCAL_HOST}/api/csv/fastlane?start_date=${formattedStartDate}&end_date=${formattedEndDate}`
    )
      .then((response) => {
        // print response
        if (!response.ok) {
          response.text().then((text) => {
            throw new Error(
              `Network response was not ok: ${response.status} ${text}`
            );
          });
          return;
        }
        return response.json();
      })

      .then((jsonData) => {
        const sortedDates = jsonData.dates.sort(
          (a, b) => new Date(a.date) - new Date(b.date)
        );

        const records = sortedDates.map(
          ({ date, history_count, time }) => {
            const isoTime = new Date(time);
            const timeString = isoTime.toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            });

            return {
              Date: date,
              Slots_Permitted: history_count,
              Time: timeString,
            };
          }
        );
        const csvData = Papa.unparse(records);
        exportData(csvData, getFileName(), "text/csv;charset=utf-8;");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const exportData = (data, fileName, type) => {
    const blob = new Blob([data], { type });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const getFileName = () => {
    const formattedStartDate = startDate.toISOString().slice(0, 10);
    const formattedEndDate = endDate.toISOString().slice(0, 10);
    return `fastlane_${formattedStartDate}_to_${formattedEndDate}.csv`;
  };

  return (
    <div
      className="scd-csv"
      role="region"
      aria-label="Download CSV for Select Range"
    >
      <div className="date-selec">
        <div className="start-selec">
          <label htmlFor="sched-start-selec">Start:</label>
          <input
            id="sched-start-selec"
            type="date"
            value={startDate ? startDate.toISOString().slice(0, 10) : ""}
            onChange={(e) => {
              const selectedDate = new Date(e.target.value);
              setStartDate(isNaN(selectedDate) ? null : selectedDate);
            }}
          />
        </div>
        <div className="end-selec">
          <label htmlFor="sched-end-selec">End:</label>
          <input
            id="sched-end-selec"
            type="date"
            value={endDate ? endDate.toISOString().slice(0, 10) : ""}
            onChange={(e) => {
              const tempDate = new Date(e.target.value);
              setEndDate(isNaN(tempDate) ? null : tempDate);
            }}
          />
        </div>
      </div>
      <IconButton
        role="button"
        className="dl-icon"
        aria-label="Export to CSV"
        variant="contained"
        color="primary"
        sx={{ height: 60 }}
        onClick={handleExportClick}
      >
        <p aria-hidden="true">Download</p>
        <CloudDownloadIcon aria-hidden="true" />
      </IconButton>
    </div>
  );
};

export default FastLaneCsvButton;
